<template>
  <div v-html="tiktokBlock"></div>
</template>

<script>
export default {
  name: "TiktokWrapper",
  props: ["tiktokBlock"],
};
</script>

<template>
  <div class="hero-header">
    <b-container class="header-container">
      <b-row class="m-0">
        <b-col class="img-col">
          <div class="hero-img">
            <b-img
              v-if="candidate.headshotPhotoPath"
              :src="`${candidate.headshotPhotoPath}`"
              alt="Candidate Profile Image"
            ></b-img>
          </div>
        </b-col>
        <b-col class="copy-col">
          <div class="header-copy">            
            <TemplateNamePosition />
            <TemplateIntroText />            
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import TemplateNamePosition from "@/components/public/NamePosition.vue";
import TemplateIntroText from "@/components/public/IntroText.vue";

export default {
  data() {
    return {
      title: "Header"
    };
  },
  components: {
    TemplateNamePosition,
    TemplateIntroText
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
   mounted() {

    let heroImg = document.querySelector('.hero-img img');
    let headerRow = document.querySelector('.hero-header .header-container .row');

    if (!heroImg) {
      headerRow.classList.add('no-headshot');
    }
  }
};
</script>

<style lang="scss" scoped></style>

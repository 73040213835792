<template>
  <div class="template-5">
    <TemplateHeader />
    <TemplateHeroHeader />
    <TemplateOpenContent v-if="candidate.openContent" />
    <TemplateFeatureText v-if="candidate.featureText || candidate.featureTitle" />
    <TemplateMediaBlock />
    <div class="template-endorsements">
      <TemplateEndorsements v-if="showEndorsements" />
      <TemplateEndorsementList />
    </div>
    <TemplateEvents1 />
    <TemplateContactInfo1 />
  </div>
</template>

<script>
import TemplateHeader from "@/components/public/Header.vue";
import TemplateHeroHeader from "@/components/public/HeroHeader.vue";
import TemplateOpenContent from "@/components/public/OpenContent.vue";
import TemplateFeatureText from "@/components/public/FeatureText.vue";
import TemplateMediaBlock from "@/components/public/MediaBlock.vue";
import TemplateEndorsements from "@/components/public/Endorsements.vue";
import TemplateEvents1 from "@/components/public/Events1.vue";
import TemplateContactInfo1 from "@/components/public/ContactInfo1.vue";
import TemplateEndorsementList from "@/components/public/EndorsementList.vue";

import { ThemeColorLoader } from "@/services/helpers";

export default {
  components: {
    TemplateHeader,
    TemplateHeroHeader,
    TemplateOpenContent,
    TemplateFeatureText,
    TemplateMediaBlock,
    TemplateEndorsements,
    TemplateEvents1,
    TemplateContactInfo1,
    TemplateEndorsementList
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
    showEndorsements() {
      return this.$store.getters["candidates/showEndorsementMenuItem"];
    }
  },
  mounted() {
    ThemeColorLoader(this.candidate.profileColor);

    // Removing Null link from event cards ---------------------//

    let eventLinks = document.querySelectorAll(".template-events-1 .card a");

    for (let i = 0; i < eventLinks.length; i++) {
      let linkText = eventLinks[i].textContent;

      if (linkText === "null") {
        eventLinks[i].classList.add("d-none");
      }
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/public/theme/_base.scss";
@import "@/assets/styles/public/theme/template-5.scss";
</style>

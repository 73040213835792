<template>
  <div
    id="tpl-events"
    class="template-events-1"
    v-if="candidate.events.length > 0"
  >
    <b-container>
      <div class="text-sm-center">
        <h3 class="mb-5">Upcoming Events</h3>
      </div>
      <div>
        <b-card-group deck class="justify-content-center">
          <b-row class="justify-content-center">
            <b-col
              cols="12"
              lg="6"
              xl="4"
              v-for="(event, i) in candidate.events"
              :key="i"
            >
              <b-card
                overlay
                :img-src="event.coverPhotoUrl"
                img-alt="Card Image"
                text-variant="white"
                :title="event.title"
                class="event-card"
              >
                <b-card-text class="event-description">
                  {{ event.description }}
                </b-card-text>

                <b-row class="more-info">
                  <b-col
                    cols="12"
                    md="6"
                    class="mb-1 mb-md-0 p-0 col-lg-12 col-xxl-6"
                  >
                    <b-card-text>
                      <time :datetime="new Date()">{{
                        event.dateTimeText
                      }}</time>
                    </b-card-text>
                  </b-col>
                  <b-col class="text-md-right text-lg-left text-xxl-right p-0">
                    <a :href="event.ctaLink" class="learn-more">{{
                      event.ctaText
                    }}</a>
                  </b-col>
                </b-row>
              </b-card>
            </b-col>
          </b-row>
        </b-card-group>
      </div>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Events",
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
  },
};
</script>

<style lang="scss" scoped></style>

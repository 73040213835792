<template>
  <div class="site-header">
    <b-navbar toggleable="lg">
      <b-container class="p-0">
        <b-navbar-brand><router-link to="/">WGAW Candidate Site</router-link></b-navbar-brand>
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item v-if="showStatements" :href="`#tpl-statement`"
              >statement
            </b-nav-item>
            <b-nav-item v-if="showEndorsements" :href="`#tpl-endorsements`">{{
              endorsementsText
            }}</b-nav-item>
            <b-nav-item v-if="showEvents" :href="`#tpl-events`"
              >events</b-nav-item
            >
            <b-nav-item :href="`#tpl-contact`">contact</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-container>
    </b-navbar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Header"
    };
  },
  mounted() {
    this.scrollTo();
  },
  computed: {
    showAbout() {
      return this.$store.getters["candidates/showAboutMenuItem"];
    },
    showStatements() {
      return this.$store.getters["candidates/showStatementMenuItem"];
    },
    showEvents() {
      return this.$store.getters["candidates/showEventsMenuItem"];
    },
    showEndorsements() {
      return this.$store.getters["candidates/showEndorsementMenuItem"];
    },
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
    endorsementsText() {
      if (this.candidate.endorsementStatements.length > 0)
        return "endorsements";
      else if (this.candidate.isPetition) return "sign petition";
      else return "endorsements";
    }
  },
  methods: {
    scrollTo() {
      const links = document.querySelectorAll(".nav-link");

      for (const link of links) {
        link.addEventListener("click", clickHandler);
      }

      function clickHandler(e) {
        e.preventDefault();
        const href = this.getAttribute("href");
        const offsetTop = document.querySelector(href).offsetTop - 100;

        scroll({
          top: offsetTop,
          behavior: "smooth"
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>

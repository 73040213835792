<template>
  <div v-if="candidate.socialLinks">
    <b-row class="m-0 mb-2" v-if="candidate.socialLinks.facebookLink">
      <a
        :href="FormatSocialLink(candidate.socialLinks.facebookLink)"
        target="_blank"
        class="social-link"
      >
        <b-col sm="1" class="p-0 social-icon text-center">
          <font-awesome-icon :icon="['fab', 'facebook-f']" />
        </b-col>
        <b-col class="p-0 social-name">
          <p class="m-0">Facebook</p>
        </b-col></a
      >
    </b-row>
    <b-row class="m-0 mb-2" v-if="candidate.socialLinks.twitterLink">
      <a
        :href="FormatSocialLink(candidate.socialLinks.twitterLink)"
        target="_blank"
        class="social-link"
      >
        <b-col sm="1" class="p-0 social-icon text-center">
          <font-awesome-icon :icon="['fab', 'twitter']" />
        </b-col>
        <b-col class="p-0 social-name">
          <p class="m-0">Twitter</p>
        </b-col></a
      >
    </b-row>
    <b-row class="m-0 mb-2" v-if="candidate.socialLinks.instagramLink">
      <a
        :href="FormatSocialLink(candidate.socialLinks.instagramLink)"
        target="_blank"
        class="social-link"
      >
        <b-col sm="1" class="p-0 social-icon text-center">
          <font-awesome-icon :icon="['fab', 'instagram']" />
        </b-col>
        <b-col class="p-0 social-name">
          <p class="m-0">Instagram</p>
        </b-col></a
      >
    </b-row>
    <b-row class="m-0 mb-2" v-if="candidate.socialLinks.linkedInLink">
      <a
        :href="FormatSocialLink(candidate.socialLinks.linkedInLink)"
        target="_blank"
        class="social-link"
      >
        <b-col sm="1" class="p-0 social-icon text-center">
          <font-awesome-icon :icon="['fab', 'linkedin']" />
        </b-col>
        <b-col class="p-0 social-name">
          <p class="m-0">LinkedIn</p>
        </b-col></a
      >
    </b-row>
    <b-row class="m-0 mb-2" v-if="candidate.socialLinks.personalWebsiteLink">
      <a
        :href="FormatSocialLink(candidate.socialLinks.personalWebsiteLink)"
        target="_blank"
        class="social-link"
      >
        <b-col sm="1" class="p-0 social-icon text-center">
          <font-awesome-icon :icon="['fas', 'globe']" />
        </b-col>
        <b-col class="p-0 social-name">
          <p class="m-0">Personal Website</p>
        </b-col></a
      >
    </b-row>
    <b-row class="m-0 mb-2" v-if="candidate.socialLinks.fawLink">
      <a
        :href="FormatSocialLink(candidate.socialLinks.fawLink)"
        target="_blank"
        class="social-link"
      >
        <b-col sm="1" class="p-0 social-icon text-center">
          <font-awesome-icon :icon="['fas', 'globe']" />
        </b-col>
        <b-col class="p-0 social-name">
          <p class="m-0">Find A Writer</p>
        </b-col></a
      >
    </b-row>
  </div>
</template>

<script>
import { FormatSocialLink } from "@/services/helpers";
export default {
  name: "media-links",
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  methods: {
    FormatSocialLink: FormatSocialLink
  }
};
</script>

<template>
  <div id="tpl-statement" class="template-open-content">
    <b-container>
      <b-card no-body v-html="candidate.openContent"></b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Open Content"
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  mounted() {

    let lineBreaks = document.querySelectorAll('.template-open-content .card p > br');

    for (let i = 0; i < lineBreaks.length; i++) {

      let breakParent = lineBreaks[i].parentNode;

      breakParent.classList.add('d-none');
      
    }

    let imgContainer = document.querySelectorAll('.template-open-content .card p > img');

    for (let i = 0; i < imgContainer.length; i++) {

      imgContainer[i].parentNode.classList.add('d-flex', 'justify-content-center');

    }
    
  }
};

</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <b-container v-if="hasEndorsements" id="member-endorsements">
      <div>
        <b-row class="flex-column flex-lg-row">
          <b-col class="p-0" lg="5">
            <h3>Member Endorsements</h3>
          </b-col>
          <b-col class="d-flex p-0">
            <div class="member-key ml-lg-auto">
              <p>
                <span class="key-color key-yellow"></span>Current or former
                officer or Board of Directors member
              </p>
              <p>
                <span class="key-color key-green"></span>Current or former
                negotiating committee member
              </p>
              <p>
                <span class="key-color key-purple"></span>Current or former WGAW
                PAC Board member
              </p>
              <p>
                <span class="key-color key-red"></span>Current or former
                committee chair or vice chair
              </p>
              <p>
                <span class="key-color key-orange"></span>Current or former
                captain
              </p>
            </div>
          </b-col>
        </b-row>
        <div v-for="(endorsement, i) in candidate.publishedEndorsementStatements" :key="i"
          class="nonCandidate-endorsements">
          <div v-if="statementHasEndorsements(endorsement.id)" class="nonCandidate-statement">
            <!-- <b-card v-if="!isPreview">
              {{
                candidate.endorsementStatements.filter(
                  x => x.id === endorsement.id
                )[0].text
              }}
            </b-card> -->
            <b-card>
              {{ candidate.endorsementStatements[0].text }}
            </b-card>
          </div>
          <p v-if="statementHasEndorsements(endorsement.id)" id="we-endorse">
            We Endorse <span>{{ candidate.name }}</span>.
          </p>
          <div class="featured-endorsements" :class="endorsementLayout">
            <div class="featured-group" v-for="(item, i) in endorsementGroups" :key="i">
              <div v-if="checkStatement(endorsement.id, item.items)">
                <h4 v-if="checkTitle(item.items)" class="group-title">
                  {{ item.title }}
                </h4>
                <b-row class="group-names">
                  <b-col cols="12" sm="6" lg="4" v-for="(member, i) in item.items" :key="i" class="list-item">
                    <p>
                      {{
                        `${member.publicEndorsement.firstName} ${member.publicEndorsement.middleName}
                                            ${member.publicEndorsement.lastName}`
                      }}
                    </p>
                    <span class="key-color key-orange" v-if="colorCheck(1, member.publicEndorsement.position)"></span>
                    <span class="key-color key-red" v-if="colorCheck(2, member.publicEndorsement.position)"></span>
                    <span class="key-color key-green" v-if="colorCheck(3, member.publicEndorsement.position)"></span>
                    <span class="key-color key-yellow" v-if="colorCheck(4, member.publicEndorsement.position)"></span>
                    <span class="key-color key-purple" v-if="colorCheck(5, member.publicEndorsement.position)"></span>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div v-if="checkStatement(endorsement.id, nonFeaturedEndorsements)"
            class="non-featured-endorsements endorsements-list">
            <b-row>
              <b-col cols="12" sm="6" lg="4" v-for="(member, i) in getStatementEndorsements(
                  nonFeaturedEndorsements,
                  endorsement.id
                )" :key="i">
                <div class="list-item">
                  <p>{{ `${member.firstName} ${member.middleName} ${member.lastName}` }}</p>
                  <span class="key-color key-orange" v-if="colorCheck(1, member.position)"></span>
                  <span class="key-color key-red" v-if="colorCheck(2, member.position)"></span>
                  <span class="key-color key-green" v-if="colorCheck(3, member.position)"></span>
                  <span class="key-color key-yellow" v-if="colorCheck(4, member.position)"></span>
                  <span class="key-color key-purple" v-if="colorCheck(5, member.position)"></span>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import Endorsement from "@/models/Endorsement";
import RecaptchaMixin from "@/mixins/recaptcha.mixin";
import * as CandidateService from "@/services/CandidateService2";
import * as SecurityService from "@/services/SecurityService";
import { setPosition } from "@/services/helpers";
import { stat } from "fs";

export default {
  mixins: [RecaptchaMixin],
  data: () => ({
    title: "EndorsementList",
  }),
  mounted() { },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
    candidateFirstName() {
      return this.candidate.name.split(" ")[0];
    },
    endorsementGroups() {
      console.log(this.candidate.endorsementGroups)
      return this.candidate.endorsementGroups;
    },
    endorsementLayout() {
      var layout = this.candidate.endorsementLayout;
      if (layout == 0) return "left-layout";
      return "center-layout";
    },
    nonFeaturedEndorsements() {
      return this.candidate.manageEndorsementList.filter(function (item) {
        return item.isFeatured == false && item.isVisible == true;
      });
    },
    hasEndorsements() {
      var data = this.candidate.manageEndorsementList.filter(function (item) {
        return item.isVisible;
      });
      if (data.length > 0) return true;

      return false;
    },
  },
  created() {
    this.checkPreview();
  },
  methods: {
    checkPreview() {
      if (document.title.includes("Preview")) {
        this.isPreview = true;
      }
    },
    checkStatement(statementId, groupItems) {
      var data = groupItems.filter(function (item) {
        return item.endorsementStatementId == statementId;
      });
      if (data.length > 0) {
        return true;
      } else {
        return false;
      }
    },
    checkTitle(groupItems) {
      var data = groupItems.filter(function (item) {
        return item.endorsementGroupId == 0;
      });
      if (data.length > 0) return false;
      return true;
    },
    getStatementEndorsements(items, statementId) {
      return items.filter(function (item) {
        return item.endorsementStatementId == statementId;
      });
    },
    statementHasEndorsements(statementId) {
      var items = this.candidate.manageEndorsementList.filter(function (item) {
        return item.endorsementStatementId == statementId;
      });

      if (items.length > 0) return true;

      return false;
    },
    hasSubmittedEndorsements() {
      this.showEndorsements =
        this.candidate.endorsementStatementsTableItems.length > 0
          ? true
          : false;
    },
    // hasEndorsements: (statements, endorsements) => {
    //   if (!statements || !endorsements) return false;
    // },
    colorCheck(id, positions) {
      if (!positions) return false;
      let pos = positions.split(",");
      pos = pos.map((x) => {
        return parseInt(x);
      });
      return pos.some((x) => x === id);
    },
  },
};
</script>

<template>
  <div id="tpl-contact" class="template-contact-info-1">
    <b-container>
      <b-row>
        <b-col lg="6">
          <div>
            <h3 class="mb-4">Contact</h3>
          </div>
          <b-form @submit.prevent="onSubmit">
            <b-form-group
              id="contact-name"
              class="form-input"
              label="Name"
              label-for="input-name"
            >
              <b-form-input
                id="input-name"
                v-model="form.name"
                type="text"
                placeholder="Enter Name"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="contact-email"
              class="form-input"
              label="Email"
              label-for="input-email"
            >
              <b-form-input
                id="input-email"
                v-model="form.email"
                type="email"
                placeholder="Enter Email"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-group
              id="contact-number"
              class="form-input"
              label="Phone"
              label-for="input-number"
            >
              <b-form-input
                id="input-number"
                v-model="form.phone"
                type="tel"
                placeholder="Enter Number"
                required
              ></b-form-input>
            </b-form-group>
            <b-form-textarea
              id="contact-textarea"
              v-model="form.message"
              placeholder="Enter Something..."
            ></b-form-textarea>
            <b-button :disabled="submitting" type="submit">
              <b-spinner small v-if="submitting"></b-spinner>
              <span class="ml-3" v-if="submitting">{{ statusText }}</span>
              <span v-else>{{ statusText }}</span>
            </b-button>
          </b-form>
        </b-col>
        <b-col id="social-links" lg="3" class="mx-auto">
          <media-links></media-links>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import MediaLinks from "@/components/public/MediaLinks";
import RecaptchaMixin from "@/mixins/recaptcha.mixin";
import * as CandidateService from "@/services/CandidateService2";

export default {
  mixins: [RecaptchaMixin],
  components: {
    MediaLinks,
  },
  data() {
    return {
      title: "Contact Info",
      form: {
        name: null,
        email: null,
        phone: null,
        message: null,
        candidateId: null,
      },
      submitting: false,
      statusText: "send message",
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    },
  },
  methods: {
    onSubmit() {
      this.submitting = true;
      this.statusText = "submitting...";
      this.form.candidateId = this.candidate.id;

      this.recaptchaCallback().then((res) => {
        if (!res) {
          this.submitting = false;
          this.statusText = "send message";
          alert("Verification failed! Try again later.");
        } else {
          CandidateService.SubmitEmailAsync(this.form)
            .then((res) => {
              this.form = {};
            })
            .catch((e) => console.error(e))
            .finally(() => {
              this.submitting = false;
              this.statusText = "send message";
            });
        }
      });

      // this.$store
      //   .dispatch("candidates/submitEmail", this.form)
      //   .then(data => {
      //     this.form = {};
      //   })
      //   .catch(e => {
      //     console.error(e);
      //   })
      //   .finally(() => {
      //     this.submitting = false;
      //     this.statusText = "send message";
      //   });
    },
  },
};
</script>

<style lang="scss" scoped></style>

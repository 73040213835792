import { render, staticRenderFns } from "./OpenContent.vue?vue&type=template&id=57ceef57&scoped=true&"
import script from "./OpenContent.vue?vue&type=script&lang=js&"
export * from "./OpenContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57ceef57",
  null
  
)

export default component.exports
<template>
  <div id="tpl-about" class="template-feature-text">
    <b-container>
      <b-card :title="candidate.featureTitle" title-tag="h3">
        <b-card-text
          class="p-feature-text"
          v-html="candidate.featureText"
        ></b-card-text>
      </b-card>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      title: "Feature Text"
    };
  },
  computed: {
    candidate() {
      return this.$store.getters["candidates/candidate"];
    }
  },
  mounted() {

    let lineBreaks = document.querySelectorAll('.template-feature-text .card .p-feature-text p > br');

    for (let i = 0; i < lineBreaks.length; i++) {

      let breakParent = lineBreaks[i].parentNode;

      breakParent.classList.add('d-none');
      
    }
    
  }
};
</script>

<style lang="scss" scoped></style>
